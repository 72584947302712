export function Header() {
  return (
    <header className="flex">
      <div className="flex-column">
        <h2 className="ff-comics">MyMemer</h2>
        <p>
          Brought to you by{" "}
          <a href="https://gogo.finance/" target="_blank">
            ❤️‍🔥🔥🐉
          </a>
        </p>
      </div>
      <div>
        <a
          className="coin-img flex"
          href="https://gogo.finance/"
          target="_blank"></a>
      </div>
    </header>
  );
}
