import { useState } from "react";
import { icons } from "./icons";

export function ControlPanel({
  onclickTemplate,
  onclickCharacter,
  addBackground,
  addSticker,
  customStickers,
  customBackground,
}) {
  const [active, setActive] = useState("memes");

  function updateActive(id) {
    setActive(id);
  }

  function importImages(r) {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item));
    });
    return images;
  }

  const characters = importImages(
    require.context("../assets/images", false, /\.png/)
  );

  const gogo = importImages(
    require.context(`../assets/images/characters/gogo`, false, /\.png/)
  );

  const pipi = importImages(
    require.context(`../assets/images/characters/pipi`, false, /\.png/)
  );

  const bibi = importImages(
    require.context(`../assets/images/characters/bibi`, false, /\.png/)
  );

  const tutu = importImages(
    require.context(`../assets/images/characters/tutu`, false, /\.png/)
  );

  const pork = importImages(
    require.context(`../assets/images/characters/pork`, false, /\.png/)
  );

  const bobo = importImages(
    require.context(`../assets/images/characters/bobo`, false, /\.png/)
  );

  const accessories = importImages(
    require.context("../assets/images/accessories", false)
  );

  const memes = importImages(
    require.context("../assets/images/templates", false)
  );

  const bg = importImages(require.context("../assets/images/bg", false));

  return (
    <>
      <div className="control-section">
        <div className="flex-column">
          <div className="flex selectors">
            <div className="flex-column">
              BACKGROUND
              <div className="bg-selection flex ff-comics">
                <div
                  className={`${
                    active === "memes" ? "active" : ""
                  } pointer meme-item flex`}
                  id="memes"
                  onClick={() => {
                    updateActive("memes");
                  }}>
                  <img src={require("../assets/images/meme.png")} alt="" />
                  {active === "memes" && <p>Memes</p>}
                </div>
                <div
                  className={`${
                    active === "bg" ? "active" : ""
                  } pointer meme-item flex`}
                  id="bg"
                  onClick={() => {
                    updateActive("bg");
                  }}>
                  <img src={require("../assets/images/bg.jpeg")} alt="" />

                  {active === "bg" && <p>Backgrounds</p>}
                </div>
                <div
                  className={`${
                    active === "add-bg" ? "active" : ""
                  } pointer meme-item flex`}
                  id="add-bg"
                  onClick={() => {
                    updateActive("add-bg");
                  }}>
                  {icons.more}
                  {active === "add-bg" && <p>Add</p>}
                </div>
              </div>
            </div>
            <div className="flex-column ">
              STICKERS
              <div className="flex stickers">
                <div className="memes-selection flex ff-comics">
                  <div
                    className={`${
                      active === "bobo" ? "active" : ""
                    } pointer meme-item flex`}
                    id="bobo"
                    onClick={() => {
                      updateActive("bobo");
                    }}>
                    <img src={characters["bobo.png"]} alt="" />{" "}
                    {active === "bobo" && <p>Bobo</p>}
                  </div>
                  <div
                    className={`${
                      active === "pork" ? "active" : ""
                    } pointer meme-item flex`}
                    id="pork"
                    onClick={() => {
                      updateActive("pork");
                    }}>
                    <img src={characters["pork.png"]} alt="" />{" "}
                    {active === "pork" && <p>Pork</p>}
                  </div>
                  <div
                    className={`${
                      active === "gogo" ? "active" : ""
                    } pointer meme-item flex`}
                    id="gogo"
                    onClick={() => {
                      updateActive("gogo");
                    }}>
                    <img src={characters["gogo.png"]} alt="" />{" "}
                    {active === "gogo" && <p>Gogo</p>}
                  </div>
                  <div
                    className={`${
                      active === "pipi" ? "active" : ""
                    } pointer meme-item flex`}
                    id="pipi"
                    onClick={() => {
                      updateActive("pipi");
                    }}>
                    <img src={characters["pipi.png"]} alt="" />
                    {active === "pipi" && <p>Pipi</p>}
                  </div>
                  <div
                    className={`${
                      active === "bibi" ? "active" : ""
                    } pointer meme-item flex`}
                    id="bibi"
                    onClick={() => {
                      updateActive("bibi");
                    }}>
                    <img src={characters["bibi.png"]} alt="" />
                    {active === "bibi" && <p>Bibi</p>}
                  </div>
                  <div
                    className={`${
                      active === "tutu" ? "active" : ""
                    } pointer meme-item flex`}
                    id="tutu"
                    onClick={() => {
                      updateActive("tutu");
                    }}>
                    <img src={characters["tutu.png"]} alt="" />
                    {active === "tutu" && <p>Tutu</p>}
                  </div>
                </div>
                <div className="memes-selection flex ff-comics">
                  <div
                    className={`${
                      active === "accessories" ? "active" : ""
                    } pointer meme-item flex`}
                    id="accessories"
                    onClick={() => {
                      updateActive("accessories");
                    }}>
                    <img
                      src={require("../assets/images/accessories/31.webp")}
                      alt=""
                    />
                    {active === "accessories" && <p>Accessories</p>}
                  </div>
                  <div
                    className={`${
                      active === "add-acc" ? "active" : ""
                    } pointer meme-item flex`}
                    id="add-acc"
                    onClick={() => {
                      updateActive("add-acc");
                    }}>
                    {icons.more}
                    {active === "add-acc" && <p>Add</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="template-row flex">
            {active === "memes" &&
              Object.values(memes).map((img, index) => {
                return (
                  <div key={index} className="bg-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickTemplate} />
                  </div>
                );
              })}
            {active === "bg" &&
              Object.values(bg).map((img, index) => {
                return (
                  <div key={index} className="bg-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickTemplate} />
                  </div>
                );
              })}
            {active === "bobo" &&
              Object.values(bobo).map((img, index) => {
                return (
                  <div key={index} className="stickers-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickCharacter} />
                  </div>
                );
              })}
            {active === "pork" &&
              Object.values(pork).map((img, index) => {
                return (
                  <div key={index} className="stickers-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickCharacter} />
                  </div>
                );
              })}
            {active === "gogo" &&
              Object.values(gogo).map((img, index) => {
                return (
                  <div key={index} className="stickers-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickCharacter} />
                  </div>
                );
              })}
            {active === "pipi" &&
              Object.values(pipi).map((img, index) => {
                return (
                  <div key={index} className="stickers-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickCharacter} />
                  </div>
                );
              })}
            {active === "bibi" &&
              Object.values(bibi).map((img, index) => {
                return (
                  <div key={index} className="stickers-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickCharacter} />
                  </div>
                );
              })}
            {active === "tutu" &&
              Object.values(tutu).map((img, index) => {
                return (
                  <div key={index} className="stickers-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickCharacter} />
                  </div>
                );
              })}
            {active === "accessories" &&
              Object.values(accessories).map((img, index) => {
                return (
                  <div key={index} className="stickers-img pointer">
                    <img src={`${img}`} alt="" onClick={onclickCharacter} />
                  </div>
                );
              })}
            {active === "add-bg" && (
              <div className="flex-column custom-tab">
                <div className="flex-column">
                  <label className="pointer">
                    <img src={require("../assets/images/bg1.png")} alt="" />
                    <input
                      type="file"
                      id="upload-img"
                      accept="image/*"
                      onChange={addBackground}
                    />
                  </label>
                  Click to add images from your local gallery
                </div>
                <div className="flex template-row">
                  {customBackground &&
                    customBackground.map((img, index) => {
                      return (
                        <div key={index} className="bg-img pointer">
                          <img
                            src={`${img}`}
                            alt=""
                            onClick={onclickTemplate}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {active === "add-acc" && (
              <div className="flex-column custom-tab">
                <div className="flex-column">
                  <label className="pointer">
                    <img src={require("../assets/images/sticker.png")} alt="" />
                    <input
                      type="file"
                      id="upload-img"
                      accept="image/*"
                      onChange={addSticker}
                    />
                  </label>
                  Click to add images from your local gallery
                </div>
                <div className="flex template-row">
                  {customStickers &&
                    customStickers.map((img, index) => {
                      return (
                        <div key={index} className="stickers-img pointer">
                          <img
                            src={`${img}`}
                            alt=""
                            onClick={onclickCharacter}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
