import "./App.css";
import { Canvas } from "./Components/Canvas";

function App() {
  return (
    <div className="flex-column wrapper">
      <Canvas />
    </div>
  );
}

export default App;
