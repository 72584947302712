import React, { useEffect, useRef, useState } from "react";
import * as fabric from "fabric";

import { icons } from "./icons";
import { ControlPanel } from "./ControlPanel";
import { useScreenSize } from "../Actions/GetWidth";
import { Buttons } from "./Buttons";
import { Header } from "./Header";

export function Canvas() {
  const canvas = useRef(null);
  const screenSize = useScreenSize();
  const [customStickers, setCustomStickers] = useState([]);
  const [customBackground, setCustomBackground] = useState([]);
  const [updateCustomItem, setUpdateCustomItem] = useState(false);

  function ranPosition() {
    return Math.floor(Math.random() * (200 - 50 + 1)) + 50;
  }

  // function setBlackText(itext) {
  //   itext.set({ fill: "black", stroke: "black" });
  // }

  var deleteIcon =
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23e64243;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
  var deleteImg = document.createElement("img");
  deleteImg.src = deleteIcon;
  function renderIcon(icon) {
    return function renderIcon(ctx, left, top, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  }

  // var textColor = require("../assets/images/black.png");
  // var textImg = document.createElement("img");
  // textImg.src = textColor;
  // function renderIcon(icon) {
  //   return function renderIcon(ctx, right, bottom, fabricObject) {
  //     var size = this.cornerSize;
  //     ctx.save();
  //     ctx.translate(right, bottom);
  //     ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  //     ctx.drawImage(icon, -size / 2, -size / 2, size, size);
  //     ctx.restore();
  //   };
  // }

  const addCharacter = (e) => {
    const file = e.target.src;

    fabric.FabricImage.fromURL(`${file}`).then((img) => {
      img.scaleToWidth(150);
      img.scaleToHeight(150);
      img.set({
        left: ranPosition(),
        top: ranPosition(),
      });
      img.controls.deleteControls = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -20,
        offsetX: 20,
        cursorStyle: "pointer",
        mouseUpHandler: deleteObject,
        render: renderIcon(deleteImg),
        cornerSize: 30,
      });

      canvas.current.add(img);
    });
  };

  const addText = (e) => {
    const itext = new fabric.IText("Click to edit", {
      left: 10,
      top: 60,
      fontSize: 23,
      fontWeight: "bold",
      fontFamily: "Open Sans",
      fill: "white",
      stroke: "black",
    });
    itext.controls.deleteControls = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -20,
      offsetX: 20,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon(deleteImg),
      cornerSize: 30,
    });
    // itext.controls.colorControls = new fabric.Control({
    //   x: -0.5,
    //   y: 0.5,
    //   offsetY: 20,
    //   offsetX: -20,
    //   cursorStyle: "pointer",
    //   mouseUpHandler: setBlackText(itext),
    //   render: renderIcon(textImg),
    //   cornerSize: 30,
    // });
    canvas.current.add(itext);
  };

  const addBackground = (e) => {
    const file = e.target.src;

    fabric.FabricImage.fromURL(`${file}`).then((img) => {
      var scaleFactor = canvas.current.width / img.width;
      img.set({
        scaleX: scaleFactor,
        scaleY: scaleFactor,
      });

      canvas.current.backgroundImage = img;
      canvas.current.requestRenderAll(canvas);
      canvas.current.setHeight(img.height * scaleFactor);
    });
  };

  const uploadBackground = (e) => {
    const file = e.target.files[0];
    customBackground.push(URL.createObjectURL(file));
    setUpdateCustomItem(!updateCustomItem);

    fabric.FabricImage.fromURL(URL.createObjectURL(file)).then((img) => {
      var scaleFactor = canvas.current.width / img.width;
      img.set({
        scaleX: scaleFactor,
        scaleY: scaleFactor,
      });

      canvas.current.backgroundImage = img;
      canvas.current.requestRenderAll(canvas);
      canvas.current.setHeight(img.height * scaleFactor);
    });
  };

  const uploadSticker = (e) => {
    const file = e.target.files[0];
    customStickers.push(URL.createObjectURL(file));
    setUpdateCustomItem(!updateCustomItem);

    fabric.FabricImage.fromURL(URL.createObjectURL(file)).then((img) => {
      img.scaleToWidth(150);
      img.scaleToHeight(150);
      img.set({
        left: ranPosition(),
        top: ranPosition(),
      });
      img.controls.deleteControls = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -20,
        offsetX: 20,
        cursorStyle: "pointer",
        mouseUpHandler: deleteObject,
        render: renderIcon(deleteImg),
        cornerSize: 30,
      });

      canvas.current.add(img);
    });

    e.target.value = null;
  };

  function saveCanvas() {
    const ext = "png";
    const base64 = canvas.current.toDataURL({
      format: ext,
      enableRetinaScaling: true,
    });
    const link = document.createElement("a");
    link.href = base64;
    link.download = `metaversico.${ext}`;
    link.click();
  }

  function deleteObject() {
    const object = canvas.current.getActiveObject();
    if (object) canvas.current.remove(object);
  }

  function setNew() {
    canvas.current.clear();
  }

  useEffect(() => {
    canvas.current = initCanvas();

    return () => {
      canvas.current.dispose();
      canvas.current = null;
    };
  }, []);

  useEffect(() => {
    const handleDelete = (e) => {
      if (e.key === "Delete") {
        deleteObject();
      }
    };

    document.addEventListener("keydown", handleDelete, true);
  }, []);

  const initCanvas = () =>
    new fabric.Canvas("canvas", {
      allowTouchScrolling: true,
    });

  return (
    <>
      {screenSize > 1000 && <Header />}
      {screenSize > 1000 && (
        <Buttons setNew={setNew} addText={addText} saveCanvas={saveCanvas} />
      )}
      <div className="flex main-container">
        <div className="flex-column canvas-section">
          {screenSize < 1000 && <Header />}
          {screenSize < 1000 && (
            <Buttons
              setNew={setNew}
              addText={addText}
              saveCanvas={saveCanvas}
            />
          )}
          <div className="canvas-wrapper">
            <div className="canvas-overlay">
              {icons.paint}
              <p className="ff-comics">Start Creating</p>
            </div>
            <canvas
              id="canvas"
              ref={canvas}
              width={screenSize > 900 ? "500px" : "360px"}
              height={screenSize > 900 ? "500px" : "360px"}></canvas>
          </div>
        </div>
        <ControlPanel
          onclickTemplate={addBackground}
          onclickCharacter={addCharacter}
          addBackground={uploadBackground}
          addSticker={uploadSticker}
          customStickers={customStickers}
          customBackground={customBackground}
          updateCustomItem={updateCustomItem}
        />
      </div>
    </>
  );
}
