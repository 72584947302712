import { icons } from "./icons";
import { useState } from "react";

export function Buttons({ addText, setNew, saveCanvas }) {
  const [open, isOpen] = useState(false);

  function setOpen() {
    isOpen(!open);
  }

  return (
    <>
      <div className="btn-container flex">
        <div className="flex">
          {open && (
            <div className="flex-column canvas-btns">
              <div
                className="flex"
                onClick={() => {
                  addText();
                  setOpen();
                }}>
                {icons.text} Add&nbsp;Text
              </div>
              <div
                className="flex"
                onClick={() => {
                  setNew();
                  setOpen();
                }}>
                {icons.reset} Reset
              </div>
            </div>
          )}
          <div
            className={open ? "active flex btn-options" : "flex btn-options"}
            onClick={setOpen}>
            {open ? icons.close : icons.more} More&nbsp;options
          </div>
        </div>
        <div className="flex btn-share" onClick={saveCanvas}>
          {icons.share} Share
        </div>
      </div>
    </>
  );
}
